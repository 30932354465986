import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { GrantSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

export const myFparGuard: CanActivateFn = () => {
  const store$ = inject(Store);
  const router = inject(Router);

  return store$.select(GrantSelectors.selectSelectedGrant).pipe(
    filter((selectedGrant) => !!selectedGrant),
    map((selectedGrant) => {
      if (selectedGrant.subrecipientId) {
        router.navigate(['/clinic-locator']);
        return false;
      } else {
        return true;
      }
    }),
  );
};
