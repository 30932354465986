import { Action, createReducer, on } from '@ngrx/store';
import {
  addTableComment,
  addTableCommentFailure,
  addTableCommentSuccess,
  addTableIssueComment,
  addTableIssueCommentFailure,
  addTableIssueCommentSuccess,
  dataQualityCheck,
  dataQualityCheckFailure,
  dataQualityCheckSuccess,
  dataQualityResults,
  dataQualityResultsFailure,
  dataQualityResultsSuccess,
  deleteTableComment,
  deleteTableCommentFailure,
  deleteTableCommentSuccess,
  deleteTableIssueComment,
  deleteTableIssueCommentFailure,
  deleteTableIssueCommentSuccess,
  getTable,
  getTableComments,
  getTableCommentsFailure,
  getTableCommentsSuccess,
  getTableFailure,
  getTableIssueComments,
  getTableIssueCommentsFailure,
  getTableIssueCommentsSuccess,
  getTableIssues,
  getTableIssuesFailure,
  getTableIssuesSuccess,
  getTableSuccess,
  holdTableIssue,
  holdTableIssueFailure,
  holdTableIssueSuccess,
  updateTable,
  updateTableComment,
  updateTableCommentFailure,
  updateTableCommentSuccess,
  updateTableFailure,
  updateTableIssue,
  updateTableIssueComment,
  updateTableIssueCommentFailure,
  updateTableIssueCommentSuccess,
  updateTableIssueFailure,
  updateTableIssueSuccess,
  updateTableSuccess,
} from './actions';
import { State, initialState } from './state';

export const fparTableFeatureKey = 'fparTable';

const fparTableReducer = createReducer(
  initialState,

  on(addTableComment, (state) => ({
    ...state,
    addTableCommentLoading: true,
  })),
  on(addTableCommentFailure, (state, { error, message }) => ({
    ...state,
    addTableCommentError: { error, message },
    addTableCommentLoading: false,
  })),
  on(addTableCommentSuccess, (state) => ({
    ...state,
    addTableCommentError: null,
    addTableCommentLoading: false,
  })),

  on(addTableIssueComment, (state) => ({
    ...state,
    addTableIssueCommentLoading: true,
  })),
  on(addTableIssueCommentFailure, (state, { error, message }) => ({
    ...state,
    addTableIssueCommentError: { error, message },
    addTableIssueCommentLoading: false,
  })),
  on(addTableIssueCommentSuccess, (state) => ({
    ...state,
    addTableIssueCommentError: null,
    addTableIssueCommentLoading: false,
  })),

  on(dataQualityCheck, (state) => ({
    ...state,
    dataQualityCheckLoading: true,
  })),
  on(dataQualityCheckFailure, (state, { error, message }) => ({
    ...state,
    dataQualityCheckError: { error, message },
    dataQualityCheckLoading: false,
  })),
  on(dataQualityCheckSuccess, (state) => ({
    ...state,
    dataQualityCheckError: null,
    dataQualityCheckLoading: false,
  })),

  on(dataQualityResults, (state) => ({
    ...state,
    dataQualityResultsLoading: true,
  })),
  on(dataQualityResultsFailure, (state, { error, message }) => ({
    ...state,
    dataQualityResults: null,
    dataQualityResultsError: { error, message },
    dataQualityResultsLoading: false,
  })),
  on(dataQualityResultsSuccess, (state, { response }) => ({
    ...state,
    dataQualityResults: response.data,
    dataQualityResultsError: null,
    dataQualityResultsLoading: false,
  })),

  on(deleteTableComment, (state) => ({
    ...state,
    deleteTableCommentLoading: true,
  })),
  on(deleteTableCommentFailure, (state, { error, message }) => ({
    ...state,
    deleteTableCommentError: { error, message },
    deleteTableCommentLoading: false,
  })),
  on(deleteTableCommentSuccess, (state) => ({
    ...state,
    deleteTableCommentError: null,
    deleteTableCommentLoading: false,
  })),

  on(deleteTableIssueComment, (state) => ({
    ...state,
    deleteTableIssueCommentLoading: true,
  })),
  on(deleteTableIssueCommentFailure, (state, { error, message }) => ({
    ...state,
    deleteTableIssueCommentError: { error, message },
    deleteTableIssueCommentLoading: false,
  })),
  on(deleteTableIssueCommentSuccess, (state) => ({
    ...state,
    deleteTableIssueCommentError: null,
    deleteTableIssueCommentLoading: false,
  })),

  on(getTable, (state) => ({ ...state, table: null, tableLoading: true })),
  on(getTableFailure, (state, { error, message }) => ({
    ...state,
    table: null,
    tableError: { error, message },
    tableLoading: false,
  })),
  on(getTableSuccess, (state, { response }) => ({
    ...state,
    table: response.data,
    tableError: null,
    tableLoading: false,
  })),

  on(getTableComments, (state) => ({ ...state, tableCommentsLoading: true })),
  on(getTableCommentsFailure, (state, { error, message }) => ({
    ...state,
    tableComments: null,
    tableCommentsError: { error, message },
    tableCommentsLoading: false,
  })),
  on(getTableCommentsSuccess, (state, { response }) => ({
    ...state,
    table: { ...state.table, comments: response.data },
    tableCommentsError: null,
    tableCommentsLoading: false,
  })),

  on(getTableIssues, (state) => ({
    ...state,
    tableIssuesError: null,
    tableIssuesLoading: true,
  })),
  on(getTableIssuesFailure, (state, { error, message }) => ({
    ...state,
    tableIssuesError: { error, message },
    tableIssuesLoading: false,
  })),
  on(getTableIssuesSuccess, (state, { response }) => ({
    ...state,
    table: { ...state.table, issues: response.data.issues },
    tableIssuesError: null,
    tableIssuesLoading: false,
  })),

  on(getTableIssueComments, (state) => ({
    ...state,
    tableIssueCommentsError: null,
    tableIssueCommentsLoading: true,
  })),
  on(getTableIssueCommentsFailure, (state, { error, message }) => ({
    ...state,
    tableIssueCommentsError: { error, message },
    tableIssueCommentsLoading: false,
  })),
  on(getTableIssueCommentsSuccess, (state) => ({
    ...state,
    tableIssueCommentsError: null,
    tableIssueCommentsLoading: false,
  })),

  on(holdTableIssue, (state) => ({
    ...state,
    holdTableIssueLoading: true,
  })),
  on(holdTableIssueFailure, (state, { error, message }) => ({
    ...state,
    holdTableIssueError: { error, message },
    holdTableIssueLoading: false,
  })),
  on(holdTableIssueSuccess, (state) => ({
    ...state,
    holdTableIssueError: null,
    holdTableIssueLoading: false,
  })),

  on(updateTable, (state) => ({
    ...state,
    updateTableLoading: true,
  })),
  on(updateTableFailure, (state, { error, message }) => ({
    ...state,
    updateTableError: { error, message },
    updateTableLoading: false,
  })),
  on(updateTableSuccess, (state) => ({
    ...state,
    updateTableError: null,
    updateTableLoading: false,
  })),

  on(updateTableComment, (state) => ({
    ...state,
    updateTableCommentLoading: true,
  })),
  on(updateTableCommentFailure, (state, { error, message }) => ({
    ...state,
    updateTableCommentError: { error, message },
    updateTableCommentLoading: false,
  })),
  on(updateTableCommentSuccess, (state) => ({
    ...state,
    updateTableCommentError: null,
    updateTableCommentLoading: false,
  })),

  on(updateTableIssue, (state) => ({
    ...state,
    updateTableIssueLoading: true,
  })),
  on(updateTableIssueFailure, (state, { error, message }) => ({
    ...state,
    updateTableIssueError: { error, message },
    updateTableIssueLoading: false,
  })),
  on(updateTableIssueSuccess, (state) => ({
    ...state,
    updateTableIssueError: null,
    updateTableIssueLoading: false,
  })),

  on(updateTableIssueComment, (state) => ({
    ...state,
    updateTableIssueCommentLoading: true,
  })),
  on(updateTableIssueCommentFailure, (state, { error, message }) => ({
    ...state,
    updateTableIssueCommentError: { error, message },
    updateTableIssueCommentLoading: false,
  })),
  on(updateTableIssueCommentSuccess, (state) => ({
    ...state,
    updateTableIssueCommentError: null,
    updateTableIssueCommentLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  fparTableReducer(state, action);
