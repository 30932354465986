<div class="link" tabindex="0">
  <div class="flex-row flex-wrap link-wrapper">
    <span class="flex-row">
      <img src="assets/flag-icon-small.png" alt="U.S. flag" />
      <span>An official website of the United States government. </span>
    </span>
    <a
      aria-controls="notice-content"
      href="#"
      (click)="toggleNotice($event)"
      [attr.aria-expanded]="noticeOpen ? 'true' : 'false'"
    >
      <span>Here's how you know</span>
      <mat-icon *ngIf="!noticeOpen">expand_more</mat-icon>
      <mat-icon *ngIf="noticeOpen">expand_less</mat-icon>
    </a>
  </div>
</div>
<div
  id="notice-content"
  class="notice"
  [class.never-opened]="!everOpened"
  [class.opened]="noticeOpen"
  [class.closed]="!noticeOpen"
  role="region"
  [attr.aria-hidden]="noticeOpen ? 'false' : 'true'"
>
  <div class="flex-row flex-wrap notice-wrapper">
    <div class="flex-row">
      <div><img src="assets/icons/icon-dot-gov.svg" alt="" role="img" /></div>
      <div>
        <h4 class="header">Official websites use .gov</h4>
        <p>
          A <b>.gov</b> website belongs to an official government organization
          in the United States.
        </p>
      </div>
    </div>
    <div class="flex-row">
      <div><img src="assets/icons/icon-https.svg" alt="" role="img" /></div>
      <div>
        <h4 class="header">Secure .gov websites use HTTPS</h4>
        <p>
          A
          <b
            >lock (
            <img
              src="assets/icons/icon-lock.svg"
              alt="lock"
              class="icon-lock"
            />
            )</b
          >
          or <b>https://</b> means you've safely connected to the .gov website.
          Share sensitive information only on official, secure websites.
        </p>
      </div>
    </div>
  </div>
</div>
