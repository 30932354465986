import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import {
  FparTableService,
  GrantService,
  ReportingPeriodService,
} from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  getTable,
  getTableFailure,
  getTableOverview,
  getTableOverviewFailure,
  getTableOverviewSuccess,
  getTableSuccess,
} from './actions';

@Injectable()
export class TableArchiveEffects {
  getReportingPeriods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportingPeriods),
      switchMap(({ grantId }) =>
        this.reportingPeriodService.getReportingPeriod(grantId).pipe(
          map((response) => getReportingPeriodsSuccess({ response })),
          catchError((error) => handleError(error, getReportingPeriodsFailure)),
        ),
      ),
    ),
  );

  getTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTable),
      switchMap(({ grantId, tableId, reportingPeriodId }) =>
        this.fparTableService
          .getTable(grantId, tableId, reportingPeriodId)
          .pipe(
            map((response) => getTableSuccess({ response })),
            catchError((error) => handleError(error, getTableFailure)),
          ),
      ),
    ),
  );

  getTableOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTableOverview),
      switchMap(({ grantId, reportingPeriodId }) =>
        this.grantService.getTableOverview(grantId, reportingPeriodId).pipe(
          map((tableOverview) =>
            getTableOverviewSuccess({
              tableOverview,
            }),
          ),
          catchError((error) => handleError(error, getTableOverviewFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private grantService: GrantService,
    private reportingPeriodService: ReportingPeriodService,
    private fparTableService: FparTableService,
  ) {}
}
