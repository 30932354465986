import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CustomDateErrorStateMatcher,
  CustomTelehealthErrorStateMatcher,
  greaterThanValidator,
} from '@app/shared/directives';
import { NotificationTypes, TableSubmissionStatus } from '@app/shared/enums';
import {
  AppStoreState,
  GrantActions,
  GrantSelectors,
  NotificationActions,
} from '@app/store';
import {
  CustomReportingPeriod,
  CustomReportingPeriodPayload,
  GrantNumberOfSites,
  GrantSubmissionStatusDetails,
} from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-supplemental-info',
  templateUrl: './supplemental-info.component.html',
  styleUrls: ['./supplemental-info.component.scss'],
})
export class SupplementalInfoComponent implements OnDestroy, OnInit {
  @Input() readOnly = true;
  @Input() grantId: number;
  @Input() isReviewPortal = false;
  @Output() public statusUpdated = new EventEmitter();

  public customReportingPeriod: CustomReportingPeriod;
  public customReportingPeriod$: Observable<CustomReportingPeriod>;
  public customReportingPeriodLoading$: Observable<boolean> =
    this.store$.select(GrantSelectors.selectCustomReportingPeriodLoading);
  public dateMatcher = new CustomDateErrorStateMatcher();
  public telehealthMatcher = new CustomTelehealthErrorStateMatcher();
  public numberOfSites$: Observable<GrantNumberOfSites>;
  public supplementalInfoForm: UntypedFormGroup;
  public numberOfSitesStatusDetails: Record<
    TableSubmissionStatus,
    GrantSubmissionStatusDetails
  >;
  public numberErrorMessage = 'Must be a whole number';
  public minDate: Date = new Date(new Date().getFullYear() - 1, 0, 1);
  public maxDate: Date = new Date(new Date().getFullYear() - 1, 11, 31);
  public numberOfSites: GrantNumberOfSites;
  public supplementalStatus: TableSubmissionStatus;

  private destroyed$ = new Subject<boolean>();
  private lastYear = new Date().getFullYear() - 1;

  constructor(
    private actions$: Actions,
    private formBuilder: UntypedFormBuilder,
    private store$: Store<AppStoreState.State>,
  ) {
    this.customReportingPeriod$ = this.store$.select(
      GrantSelectors.selectCustomReportingPeriod,
    );
    this.numberOfSites$ = this.store$.select(
      GrantSelectors.selectNumberOfSites,
    );

    this.actions$
      .pipe(
        ofType(
          GrantActions.deleteCustomReportingPeriodFailure,
          GrantActions.getCustomReportingPeriodFailure,
          GrantActions.updateCustomReportingPeriodFailure,
          GrantActions.getNumberOfSitesFailure,
          GrantActions.updateNumberOfSitesFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.supplementalInfoForm.enable();
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(
          GrantActions.deleteCustomReportingPeriodSuccess,
          GrantActions.updateNumberOfSitesSuccess,
          GrantActions.updateCustomReportingPeriodSuccess,
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.resetForm();
          this.fetchData();
          this.supplementalInfoForm.enable();
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: 'Your information has been saved.',
            }),
          );
          setTimeout(() => {
            this.store$.dispatch(NotificationActions.reset());
          }, 3000);
        }),
      )
      .subscribe();

    this.numberOfSitesStatusDetails = {
      [TableSubmissionStatus.NotStarted]: {
        icon: 'block',
        class: 'notstarted',
      },
      [TableSubmissionStatus.InProgress]: {
        icon: 'pending',
        class: 'inprogress',
      },
      [TableSubmissionStatus.Completed]: {
        icon: 'check_circle_outline',
        class: 'complete',
      },
      [TableSubmissionStatus.NotRequired]: {
        icon: 'block',
        class: 'notrequired',
      },
    };
  }

  ngOnDestroy(): void {
    if (this.isReviewPortal) {
      this.store$.dispatch(GrantActions.resetNumberOfSites());
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchData();

    combineLatest([this.numberOfSites$, this.customReportingPeriod$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([numberOfSites, reportingPeriod]) => {
        this.supplementalStatus = numberOfSites?.status;
        this.hydrateForm(numberOfSites, reportingPeriod);
        this.statusUpdated.emit(numberOfSites.status);
      });

    this.supplementalInfoForm
      .get('differentFromApp')
      .valueChanges.subscribe((value) => this.setRequiredValidation(value));

    this.supplementalInfoForm
      .get('customReportingPeriods')
      .valueChanges.subscribe((value) => this.setDateRequired(value));
  }

  public buildForm(): void {
    this.supplementalInfoForm = this.formBuilder.group(
      {
        customReportingPeriods: [''],
        reportingPeriodStart: [''],
        reportingPeriodEnd: [''],
        titlexSubrecipients: [
          '',
          { validators: [Validators.pattern(/^[0-9]*$/)] },
        ],
        titlexSites: ['', { validators: [Validators.pattern(/^[0-9]*$/)] }],
        telehealthClinics: [
          '',
          { validators: [Validators.pattern(/^[0-9]*$/)] },
        ],
        differentFromApp: [''],
        differentFromAppReason: [''],
      },
      {
        validators: [
          greaterThanValidator(
            ['reportingPeriodStart', 'reportingPeriodEnd'],
            'invalidDateRange',
          ),
          greaterThanValidator(
            ['telehealthClinics', 'titlexSites'],
            'maxSitesExceeded',
          ),
        ],
      },
    );
    if (this.readOnly) {
      this.supplementalInfoForm.disable();
    }
  }

  public isDirty(): boolean {
    return !!this.supplementalInfoForm.dirty;
  }

  public resetForm(): void {
    if (!this.supplementalInfoForm.controls.customReportingPeriods.value) {
      this.supplementalInfoForm.patchValue({
        reportingPeriodStart: new Date(`1/1/${this.lastYear}`),
        reportingPeriodEnd: new Date(`12/31/${this.lastYear}`),
      });
    }

    if (!this.supplementalInfoForm.controls.differentFromApp.value) {
      this.supplementalInfoForm.patchValue({ differentFromAppReason: null });
    }

    this.supplementalInfoForm.markAsUntouched();
    this.supplementalInfoForm.markAsPristine();
  }

  public setDateRequired(isRequired: boolean) {
    if (isRequired) {
      this.supplementalInfoForm.controls.reportingPeriodStart.setValidators([
        Validators.required,
      ]);
      this.supplementalInfoForm.controls.reportingPeriodEnd.setValidators([
        Validators.required,
      ]);
    } else {
      this.supplementalInfoForm.controls.reportingPeriodStart.clearValidators();
      this.supplementalInfoForm.controls.reportingPeriodEnd.clearValidators();
    }
    this.supplementalInfoForm.controls.reportingPeriodStart.updateValueAndValidity();
    this.supplementalInfoForm.controls.reportingPeriodEnd.updateValueAndValidity();
  }

  public setRequiredValidation(isRequired: boolean) {
    if (isRequired) {
      this.supplementalInfoForm.controls.differentFromAppReason.setValidators([
        Validators.required,
      ]);
    } else {
      this.supplementalInfoForm.controls.differentFromAppReason.clearValidators();
    }
    this.supplementalInfoForm.controls.differentFromAppReason.updateValueAndValidity();
  }

  public updateSupplementalInfoForm(): void {
    const grantId = this.grantId;

    const {
      differentFromApp,
      telehealthClinics,
      titlexSubrecipients,
      titlexSites,
      reportingPeriodStart,
      reportingPeriodEnd,
    } = this.supplementalInfoForm.value;

    const differentFromAppReason = this.supplementalInfoForm.controls
      .differentFromApp.value
      ? this.supplementalInfoForm.controls.differentFromAppReason.value
      : null;

    const isCustomReportingPeriod =
      this.supplementalInfoForm.controls.customReportingPeriods.value;

    const payload: GrantNumberOfSites = {
      grantId,
      differentFromApp,
      differentFromAppReason,
      telehealthClinics,
      titlexSubrecipients,
      titlexSites,
    };

    const reportingPayload: CustomReportingPeriodPayload =
      isCustomReportingPeriod
        ? { grantId, reportingPeriodStart, reportingPeriodEnd }
        : { grantId };

    const reportingPeriodAction = isCustomReportingPeriod
      ? GrantActions.updateCustomReportingPeriod
      : GrantActions.deleteCustomReportingPeriod;

    this.supplementalInfoForm.disable();

    this.store$.dispatch(GrantActions.updateNumberOfSites({ payload }));
    this.store$.dispatch(reportingPeriodAction({ reportingPayload }));
  }

  private fetchData(): void {
    this.store$.dispatch(
      GrantActions.getNumberOfSites({
        grantId: this.grantId,
      }),
    );
    this.store$.dispatch(
      GrantActions.getCustomReportingPeriod({
        grantId: this.grantId,
      }),
    );
  }

  private hydrateForm(
    data: GrantNumberOfSites,
    reportingPeriod: CustomReportingPeriod,
  ) {
    const reportingPeriodStart = reportingPeriod?.reportingPeriodStart
      ? new Date(`${reportingPeriod?.reportingPeriodStart}T00:00:00`)
      : new Date(`1/1/${this.lastYear}`);
    const reportingPeriodEnd = reportingPeriod?.reportingPeriodEnd
      ? new Date(`${reportingPeriod?.reportingPeriodEnd}T00:00:00`)
      : new Date(`12/31/${this.lastYear}`);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { grantId, status, ...formData } = data;
    const combinedData = {
      ...formData,
      ...reportingPeriod,
      customReportingPeriods: reportingPeriod !== null,
      reportingPeriodStart,
      reportingPeriodEnd,
    };

    this.supplementalInfoForm.patchValue({ ...combinedData });
    this.supplementalInfoForm.markAsPristine();
  }
}
