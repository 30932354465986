<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>{{ cardIcon }}</mat-icon>
    </div>
    <mat-card-title>{{ cardTitle }}</mat-card-title>
    <mat-card-subtitle class="card-subtitle">{{
      cardSubTitle
    }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions>
    <ng-container
      *ngIf="buttonType === 'flat'; then flat; else stroked"
    ></ng-container>
  </mat-card-actions>
</mat-card>

<ng-template #stroked>
  <a
    *ngIf="cardLinkUrl"
    mat-stroked-button
    color="primary"
    [routerLink]="cardLinkUrl"
  >
    <span>{{ cardLinkText }}</span>
    <mat-icon *ngIf="showButtonIcon">{{ cardIcon }}</mat-icon>
  </a>
</ng-template>

<ng-template #flat>
  <a
    *ngIf="cardLinkUrl"
    mat-flat-button
    color="primary"
    [routerLink]="cardLinkUrl"
  >
    <span>{{ cardLinkText }}</span>
    <mat-icon *ngIf="showButtonIcon">{{ cardIcon }}</mat-icon>
  </a>
</ng-template>
